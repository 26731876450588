import React from 'react';
import './App.css';

function App() {
  const copyContractAddress = () => {
    const contractAddress = '5NnKDh7jRFXbMvBJFN3yiZshm9QNZqkRoAeArKFP2Lpc'; // Corrected contract address
    navigator.clipboard.writeText(contractAddress).then(() => {
      alert('Contract Address Copied!');
    });
  };

  return (
    <div
      className="container"
      style={{
        backgroundImage: `url(${process.env.PUBLIC_URL + '/bg.png'})`,
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center center',
        backgroundSize: 'cover',
        height: '100vh',
      }}
    >
      {/* Website title "HOPIUM" in the upper left */}
      <div className="title">HOPIUM</div>

      {/* Text further to the right, and formatted into two lines */}
      <div className="text">
        Survive the dip, inhale the hopium, <br /> and be ready—our time is near!
      </div>

      {/* Contract Address with a styled border */}
      <div className="contract" onClick={copyContractAddress}>
        CA: <span>5NnKDh7jRFXbMvBJFN3yiZshm9QNZqkRoAeArKFP2Lpc</span>
      </div>

      {/* Candle image with adjusted size and position */}
      <img className="candle" src={process.env.PUBLIC_URL + '/hope.png'} alt="Candle of Hope" />

      {/* Dexscreener, Dextools, and X (Twitter) icons below the candle */}
      <div className="icons">
        <a href="https://dexscreener.com/solana/5nnkdh7jrfxbmvbjfn3yizshm9qnzqkroaearkfp2lpc" target="_blank" rel="noopener noreferrer">
          <img src={process.env.PUBLIC_URL + '/dexscreener.png'} alt="Dexscreener" />
        </a>


        {/* X (Twitter) logo without a hyperlink and with a tooltip */}
 
      </div>
    </div>
  );
}

export default App;
